import { FaBullhorn, FaLaptop, FaMobileAlt } from "react-icons/fa";

const Services = [
    {
      id: 1,
      title: "Website & Mobile Applications",
      icon: <FaMobileAlt />,
      description:
        "Develop information, administration, and promotion systems based on websites and mobile applications as needed, aiming to increase profits and efficiency of business operations in your company. \n ✅ Android App Development \n ✅ UI/UX Web/Mobile App Design \n ✅ WordPress Web Development \n ✅ Custom Web App Development",
    },
    {
      id: 2,
      title: "Branding & Content Creative",
      icon: <FaBullhorn />,
      description:
        "Boost your business reputation and create a strong, memorable image with our Branding & Content Creative services. We provide what your customers are looking for, helping you stand out positively in the public eye. \n ✅ Logo Design \n ✅ Stationary Design \n ✅ Marketing Kit Design \n ✅ Photo & Videography \n ✅ Social Media Content Design ",
    },
    {
      id: 3,
      title: "Digital Marketing & Advertising",
      icon: <FaLaptop />,
      description:
        "Attract new customers and increase sales by utilizing our online marketing services, including Digital Marketing & Advertising. Tailored to your marketing budget, target audience, and business reach, we help you achieve effective results. \n ✅ Google Search Ads \n ✅ GDN & Youtube Ads \n ✅ Facebook & Instagram Ads \n ✅ Search Engine Optimization",
    },
  ];

  export default Services;