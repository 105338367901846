const blogs = [
  {
    _id: 1,
    title: "Google Project Soli",
    path: "optimasi-seo-instagram",
    date: "Volume 12 Issue 3, March 2023",
    img: "https://kstatic.googleusercontent.com/files/9f0e7d6946df274dc163899bd2ba98c5f2a283296271ed276c2ccd853efcd66896abd1341252c284ca40abbbdda454db56a5c2d2cdc4a461d2a809516f236fa3",
    instagram_link: "https://www.instagram.com/",
    linkedin_link: "https://www.linkedin.com/",
    more_link: "https://www.ijsr.net/archive/v12i3/SR23309224010.pdf",
    tags: ["ProjectSoli", "GestureControl", "RadarInteraction", "ContactlessTech", "AdvancedSensing", "FutureOfInteraction"],
    description: "Google Project Soli is a notable touchless point of interaction innovation that uses radar to detect and interpret human motions. The technology has the possibly altogether upgrade convenience for different gadgets and applications by permitting clients to interact with them through touchless points of interaction. This research paper gives an in-depth investigation of Google Project Soli, including its set of experiences, innovation, applications, difficulties, and future potential. The paper additionally examines the administrative obstacles looked by Soli and the new waiver allowed by the Government Interchanges Commission (FCC) to work Soli at higher power levels, preparing for coordination into business items. By and large, this examination paper features the capability of Google Project Soli to alter the manner in which we cooperate with innovation.",
  },
];

export default blogs;
