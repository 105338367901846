const TimPeople = [

    {
        id: 2,
        title: "Chandraj G.",
        icon: "Chand",
        description:
            "Business Development Management",
    },
    {
        id: 4,
        title: "Ashutosh Das",
        icon: "Asu",
        description:
            "Chief Technology Officer",
    },
];

export default TimPeople;
