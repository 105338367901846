import MisiMSN from "../assets/Goal/Mission.png"
import LayananMSN from "../assets/Goal/Service.png"
import VisiMSN from "../assets/Goal/Vision.png"

const visiMisi = [
    {
        id: 1,
        title: "Mission",
        icon: <img src={MisiMSN} alt="Mission" />,
        description:
            "Become a reliable private company in providing services and trading in accordance with the CAPT Technology owned.",
    },
    {
        id: 2,
        title: "Service",
        icon: <img src={LayananMSN} alt="Service" />,
        description:
            "Service Earn consumer loyalty, give them a memorable experience by creating mutually beneficial relationships.",
    },
    {
        id: 3,
        title: "Vision",
        icon: <img src={VisiMSN} alt="Vision" />,
        description:
            "In carrying out its duties, CAPT Technology always maintains, professionalism, honesty, togetherness, order, and occupational health so that quality work is produced.",
    },
];

export default visiMisi;
