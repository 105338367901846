import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { BottomLine } from "../../../components";
import { useInView } from "react-intersection-observer";
import {
    sectionBodyAnimation,
} from "../../../hooks/useAnimation"

const About = () => {
    const [ref, inView] = useInView();
    const [viewDiv, setViewDiv] = useState(false);
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            setViewDiv(true);
        } else {
            setViewDiv(false);
        }
    }, [inView, animation]);
    return (
        <div className="pt-2 my-16 parent">
            <div className="">
                <motion.div
                    className="mb-12"
                    initial={{ y: -200, opacity: 0 }}
                    animate={{
                        y: 0,
                        opacity: 1,
                        transition: { duration: 1, type: "spring" },
                    }}
                >
                    <h3 className="text-center text-accent">What is CAPT Technology</h3>
                    <h1 className="text-4xl font-semibold text-center drop-shadow-md">
                        A <span className="text-primary">CAPT</span>
                    </h1>
                    <BottomLine />
                </motion.div>
                <div className="items-center px-5 pt-4 text-center sm:px-20">
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={viewDiv && "visible"}
                        variants={sectionBodyAnimation}
                    >
                        <p className="font-medium text-accent">
                        Founded on January 1st, 2023, CAPT Technology is the brainchild of four friends who share a passion for innovation. We specialize in a spectrum of services including Mobile and Web Development, Content and Blog Writing, App Development, and captivating Logo Design. Our mission is to infuse creativity and technical prowess into every project, reshaping the digital landscape one innovation at a time.
                        </p>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default About;
